<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="6">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Business Certification</h2>

          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            :disabled="form.$busy || loadingInitValues"
            :loading="form.$busy || loadingInitValues"
            @click="saveAndNext"
            depressed
            text
          >
            <span> Next </span>
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>
        </div>

        <div>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.abn"
                v-mask="'###########'"
                label="ABN"
                :error-messages="form.$getError('abn')"
                :disabled="form.$busy || loadingInitValues"
                outlined
                flat
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                v-model="form.business_type_id"
                label="Type of Business"
                :loading="loadingInitValues"
                :error-messages="form.$getError('business_type_id')"
                :items="businessTypes"
                :disabled="form.$busy || loadingInitValues"
                item-value="business_type_id"
                item-text="type"
                @change="(value) => setValueToNull(value, 'business_type_id')"
                clearable
                outlined
                flat
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.license_number"
                label="License Number"
                :error-messages="form.$getError('license_number')"
                :disabled="form.$busy || loadingInitValues"
                outlined
                flat
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                v-model="form.trade_license_id"
                label="Trade License"
                :items="licenses"
                :loading="loadingInitValues"
                :error-messages="form.$getError('trade_license_id')"
                :disabled="form.$busy || loadingInitValues"
                item-value="trade_license_id"
                item-text="trade_license_name"
                @change="(value) => setValueToNull(value, 'trade_license_id')"
                clearable
                outlined
                flat
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import BusinessType from '@/models/BusinessType'
import TradeLicense from '@/models/TradeLicense'
import isDirtyFrom from './mixins/DirtyForm'
import FormMixin from '@/utils/mixins/Form'
import Tradie from '@/models/Admin/Tradie'
import Form from '@/utils/form'

import { pick, extend, isFunction } from 'lodash'
import { mapActions, mapMutations, mapState } from 'vuex'
import { mdiArrowRight } from '@mdi/js'
import { mask } from 'vue-the-mask'

export default {
  name: 'NewTradieCertification',
  components: { ConfirmDialog },

  mixins: [FormMixin, isDirtyFrom],

  directives: { mask },

  data() {
    return {
      businessTypes: [],
      licenses: [],
      loadingInitValues: true,

      icons: {
        next: mdiArrowRight,
      },

      form: new Form({
        id: this.$attrs.id,
        abn: '',
        license_number: '',
        business_type_id: null,
        trade_license_id: null,
      }),
    }
  },

  computed: {
    ...mapState('newTradie', ['tradie']),

    withNewTradie() {
      return !!this.$attrs.id
    },
  },

  created() {
    this.initValues()
  },

  methods: {
    ...mapActions('newTradie', ['getTradieDetails']),
    ...mapMutations('newTradie', ['setNewTradie']),

    async initValues() {
      this.loadingInitValues = true

      if (this.withNewTradie) {
        await this.getTradieDetails(this.$attrs.id)
      }

      await this.getTradeLicenses()
      await this.getBustinessTypes()
      await this.setFormData()

      this.loadingInitValues = false
    },

    async getBustinessTypes() {
      const { data } = await BusinessType.get()

      this.businessTypes = data
    },

    async getTradeLicenses() {
      const { data } = await TradeLicense.get()

      this.licenses = data
    },

    saveAndNext(redirect = null) {
      this.form.$clearErrors()
      this.form.$busy = true

      const tradie = new Tradie(this.form.$data())

      tradie
        .save()
        .then(async ({ data }) => {
          await this.setNewTradie(data)

          const { id } = data

          if (isFunction(redirect)) {
            return redirect()
          }

          this.$router.push({
            name: 'new-tradie-details',
            params: { id },
          })
        })
        .catch(({ response }) => {
          this.form.$wait(() => {
            if (response && response.status === 422) {
              this.form.$setErrors(response.data.errors)
            }

            this.form.$busy = false
          })
        })
        .finally(() => {
          this.form.$wait(() => (this.form.$busy = false))
        })
    },

    async setFormData() {
      this.form = new Form(
        extend(
          this.form.$data(),
          pick(this.tradie, [
            'id',
            'abn',
            'license_number',
            'business_type_id',
            'trade_license_id',
          ])
        )
      )
    },
  },

  watch: {
    'tradie.id'() {
      this.setFormData()
    },
  },
}
</script>
